import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import WinterContainer from "../../components/Winter/WinterContainer"
import SEO from "../../components/seo"

import "../../styles/Global.css"
import "../../styles/layout.module.css"
import AgeGate2 from "../../components/agegate"

const WinterPage = props => {
  //Aca traemos el listado de todos los hoteles, Cada uno esta dentro de un node
  const [age, setAge] = useState(true)
  const [provincesFromQuery, setProvincesFromQuery] = useState([])

  useEffect(() => {
    let local = sessionStorage.getItem('age')
    if (local) setAge(false)
}, [])
  useEffect(() => {
    const provinces = props.data.allSitePage.edges
    let arr = []
    provinces.map(function(item) {
      arr.push(item.node.context)
    })
    setProvincesFromQuery(arr)
  }, [])

  return (
    !age ?
    <div>
      <SEO title="HAY ALGO AHÍ AFUERA QUE NOS HACE BIEN POR DENTRO.." />
      <WinterContainer page={"Index"} provinces={provincesFromQuery} />
    </div>
    :
    <AgeGate2 setAge={setAge}/>
  )
}

export default WinterPage

export const query = graphql`
  query MainWinterQuery {
    allSitePage(sort: { fields: id }) {
      edges {
        node {
          id
          context {
            id
            images_provinces
            name
          }
        }
      }
    }
  }
`
